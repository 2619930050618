import { HomePartnersSectionProps } from "../../model";

export default function PartnersSection({ partners }: HomePartnersSectionProps) {
	const imgClasses = "h-107px w-107px rounded-50% border-2 border#p border-solid";

	return (
		<section class="flex flex-col gap-40px bg#paper items-center justify-between pt-80px">
			<h2 class="text-31px font-bold leading-34px">Our partners</h2>
			<div class="flex gap-40px">
				{partners.map((partner) => {
					return !!partner.src ? (
						<img class={imgClasses} src={partner.src} alt={partner.alt} />
					) : (
						<div class={imgClasses} />
					);
				})}
			</div>
			<hr class="max-w-screen w-screen h-2px bg#p mt-40px" />
		</section>
	);
}
